import React from "react"

function FooterLogo() {
    return (
        <div className="footer_logo">
            <img src="/images/footerLogo.png" alt="footer logo" />
        </div>
    )
}

export default FooterLogo
