import { Outlet } from "react-router-dom"

function MyLayouts() {
    return (
        <>
            <Outlet />
        </>
    )
}
export default MyLayouts
