export const itemData = [
    { id: 20, img: "./images/gallery01.jpg", title: "massage office" },
    { id: 21, img: "./images/gallery02.jpg", title: "massage office" },

    { id: 22, img: "./images/gallery03.jpg", title: "massage office" },
    { id: 23, img: "./images/gallery04.jpg", title: "massage office" },
    { id: 0, img: "./images/gallery05.jpg", title: "massage office" },
    { id: 1, img: "./images/gallery06.jpg", title: "massage office" },
    { id: 2, img: "./images/gallery07.jpg", title: "massage office" },
    { id: 5, img: "./images/gallery08.jpg", title: "massage office" },
]
export const itemMenu = [
    {
        id: 101,
        title: "Послуги",
        src: "#CardServices",
    },
    {
        id: 102,
        title: "Про нас",
        src: "#AboutUs",
    },
    {
        id: 103,
        title: "Карти",
        src: "#Card",
    },

    {
        id: 106,
        title: "Тренери",
        src: "#CardCoach",
    },
    {
        id: 107,
        title: "Галерея",
        src: "#Gallery",
    },
    {
        id: 108,
        title: "Розклад",
        src: "#Schedule",
    },
]

export const slideArr = [
    { id: 0, title: "", img: "./images/slider01.jpg", alt: "photo" },
    { id: 1, title: "", img: "./images/slider02.png", alt: "photo" },
    { id: 2, title: "", img: "./images/slider03.jpg", alt: "photo" },
]

export const coachArray = [
    {
        id: 0,
        name: "ЯКУЩЕНКО ОЛЕКСІЙ",
        jobTitle:
            "Силовий та функціональний тренінг. Дієтологія. Бодібілдинг. ",

        img: "./images/coach06.jpg",
        linkOnForm: "Запис до Якущенко Олексій",
        experience: "9 Років",
    },
    {
        id: 1,
        name: "КАЛИНА СЕРГІЙ",
        jobTitle:
            "Тренажерний зал, Crossfit, Функціональний тренінг, Реабілітація, Реабілітація Спини та Корекція Постави.Силовий Тренінг.",

        img: "./images/coach04.jpg",
        linkOnForm: "Запис до Калина Сергій",
        experience: "10 Років",
    },
    {
        id: 2,
        name: "ТАРАН АНАСТАСІЯ",
        jobTitle:
            "Силовий та функціональний тренінг. Реабілітація. Дієтологія.",

        img: "./images/coach01.jpg",
        linkOnForm: "Запис до Таран Анастасія",
        experience: "11 Років",
    },
    {
        id: 3,
        name: "ГУРЗА КАРОЛІНА",
        jobTitle: "Силовий та функціональний тренінг. Дієтологія.",

        img: "./images/coach08.jpg",
        linkOnForm: "Запис до Гурза Кароліна",
        experience: "5 Років",
    },
    {
        id: 4,
        name: "БАСНЄВ ВІТАЛІЙ",
        jobTitle: "Силовий та функціональний тренінг. Реабілітація. Mind Body.",

        img: "./images/coach05.jpg",
        linkOnForm: "Запис до Баснев Віталій",
        experience: "7 Років",
    },
    {
        id: 5,
        name: "ШУЛЯК ТИМОФІЙ",
        jobTitle:
            "Силовий тренінг. Бодібілдинг. Практикуючий лікар – анестезист.",

        img: "./images/coach07.jpg",
        linkOnForm: "Запис до Шуляк Тимофій",
        experience: "6 Років",
    },

    {
        id: 7,
        name: "ОЛЕФІР ДАНА",
        jobTitle:
            "TRX, Functional Training, Tabata, Stretching, силові та функціональні тренування , TBW, HIIT ( високоінтенсивні та інтервальні тренування ).",
        img: "./images/coach03.jpg",
        linkOnForm: "Запис до Олефір Дана",
        experience: "6 років",
    },
]

export const cardServicesArr = [
    {
        id: 0,
        header: "ТРЕНАЖЕРНИЙ ЗАЛ",
        img: "./images/home-11-img-1.jpg",
        paragraph1: "",
        paragraph2: "",
        list: [
            "Простір для ефективних та комфортних тренувань.",
            "Якісне сучасне  обладнання",
            "Сучасний LOFT-дизайн",
            "Надпотужна вентиляція",
        ],
        nameButton: "Записатись на заняття",
        linkOnForm: "Запис в трежерний зал",
    },
    {
        id: 1,
        header: "ГРУПОВІ ЗАНЯТТЯ",
        img: "./images/home-11-img-2.jpg",
        paragraph1: ``,
        paragraph2: ``,
        nameButton: "Дивитись Розклад",
        list: [
            "Різноманітні програми",
            "Командний дух",
            "Постійний ритм",
            "Контроль інструктора",
            "Оптимальне поєднання для досягнення ваших цілей!",
        ],
        src: "/schedule",
    },
    {
        id: 2,
        header: "ПЕРСОНАЛЬНІ ТРЕНУВАННЯ",
        img: "./images/home-11-img-3.jpg",
        list: [
            "Персональні тренування - гарантовані результати! -Індивідуальні програми з урахуванням побажань та рівня підготовки",

            "Професійні рекомендації по харчуванню. -Особистий тренер - ключ до ефективних тренувань та здорового способу життя. -З тренером шлях до мети стає коротшим, ефективнішим та безпечнішим!",
        ],
        nameButton: "Записатись на заняття",
        linkOnForm: "Запис на персональні тренування",
    },
    {
        id: 6,
        header: "НАСТІЛЬНИЙ ТЕНІС",
        img: "./images/ping_pong.jpg",
        list: [
            `Настільний теніс – це одна з ігор спортивної спрямованості, 
        яка представляє собою перекидання м’яча через розділений навпіл сіткою стіл за допомогою спеціальних ракеток.`,
        ],
        nameButton: "Записатись",
        linkOnForm: "Запис на Кроссфіт",
    },
    {
        id: 4,
        header: "МАСАЖ",
        img: "./images/IMG_6714.jpg",
        paragraph1: `LADIES CLUB
        - Студія корекції фігури та масажу. `,
        list: [
            "Досвідчені майстри та авторська програма корекції.",
            "Зменшення обсягів, виточування силуету, антицелюлітний ефект.",
            "Поліпшення тонусу шкіри та згладжування стрій. Стимулювання метаболізму для вражаючих результатів. Масаж – ідеальне доповнення до спорту та фізичних навантажень.",
        ],
        nameButton: "",
    },
    {
        id: 5,
        header: "CROSSFIT",
        img: "./images/cardservice06.jpg",
        paragraph1: `На заняттях розвиваються такі якості:`,
        list: [
            "Витривалість серцево-судинної та дихальної систем",
            "Загальна Витривалість",
            "Сила",
            "Гнучкість",
            "Потужність",
            "Швидкість",
            "Координація",
            "Спритність",
            "Баланс",
            "Точність",
        ],
        nameButton: "Записатись",
        linkOnForm: "Запис на Кроссфіт",
    },
]

export const cardArray = [
    {
        id: 0,
        name: "РАНОК",
        coast: "2000uan",
        description: [
            "Пн-Пт 08:00 – 17:00",
            "Сб-Нд 09:00 – 14:00",
            "Тренажерний зал",
            "Кардіотеатр",
            "Зона Crossfit",
            "Призупинка до 30 днів",
            "-10% персональні тренування",
        ],
        linkOnForm: "Замовити Morning карту",
    },
    {
        id: 1,
        name: "БЕЗЛІМІТ",
        coast: "3000uan",
        description: [
            "Пн-Пт 08:00 – 21:00",
            "Сб-Нд 09:00 – 19:00",
            "Тренажерний зал",
            "Кардіотеатр",
            "Зона Crossfit",
            "Призупинка до 30 днів",
            "-10% персональні тренування",
        ],
        linkOnForm: "Замовити Full day карту",
    },
    {
        id: 2,
        name: "MIX",
        coast: "1500uan",
        description: [
            "Пн-Пт 08:00 – 17:00",
            "Сб-Нд 09:00 – 14:00",
            "Групові заняття – БЕЗЛІМІТНО!",
            "Тренажерний зал",
            "Кардіотеатр",
            "Зона Crossfit",
            "Призупинка до 30 днів",
            "-10% персональні тренування",
        ],
        linkOnForm: "Замовити All Inclusive карту",
    },
    {
        id: 3,
        name: "ГРУПОВІ ЗАНЯТТЯ",
        coast: "1500uan",
        description: [
            "Пн-Пт 08:00 – 17:00",
            "Сб-Нд 09:00 – 14:00",
            "*за розкладом",
            "Силові тренування",
            "Аєробні тренування",
            "Реабілітаційні тренування",
            "Функціональні тренування",
            "-10% персональні тренування",
        ],
        linkOnForm: "Замовити Group Training карту",
    },
    {
        id: 4,
        name: "КОРПОРАТИВ",
        coast: "1500uan",
        description: [
            "Пн-Пт 08:00 – 17:00",
            "Сб-Нд 09:00 – 14:00",
            "*за розкладом",
            "Силові тренування",
            "Аєробні тренування",
            "Реабілітаційні тренування",
            "Функціональні тренування",
            "-10% персональні тренування",
            "3 безкоштовних візити для близьких та друзів",
        ],
        linkOnForm: "Замовити Group Training карту",
    },
]

export const tableSchedule = [
    {
        day: ["Понеділок", "ПН"],
        training: [
            {
                time: "11:00",
                name: "UPPER BODY",
                description:
                    "Lorem ipsum dolor sit, amet consectetur\nadipisicing elit. Voluptas at distinctio nobis\nmagni ea facilis earum qui.",
                id: "4165bb55-cf27-4b10-a74a-97c8c531b1b6",
            },
            {
                time: "18:00",
                name: "FAT BURN",
                description:
                    "Lorem ipsum dolor sit, amet consectetur\nadipisicing elit. Voluptas at distinctio nobis\nmagni ea facilis earum qui.",
                id: "875caa80-8671-43a2-9e18-b88c845771d4",
            },
            {
                time: "19:00",
                name: "STRETCHING",
                description:
                    "Lorem ipsum dolor sit, amet consectetur\nadipisicing elit. Voluptas at distinctio nobis\nmagni ea facilis earum qui.",
                id: "ed026a0b-80ff-4d77-a2e5-0bf030d1faa1",
            },
        ],
        id: "46068e88-3e3d-48b9-8c1b-adfd705c6cec",
    },
    {
        day: ["Вівторок", "ВТ"],
        training: [
            {
                time: "12:00",
                name: "CIRCUIT TRAINING",
                description:
                    "Lorem ipsum dolor sit, amet consectetur\nadipisicing elit. Voluptas at distinctio nobis\nmagni ea facilis earum qui.",
                id: "1a95c3eb-873b-4bf0-8f41-1114893d421b",
            },
            {
                time: "13:00",
                name: "STRETCHING",
                description:
                    "Lorem ipsum dolor sit, amet consectetur\nadipisicing elit. Voluptas at distinctio nobis\nmagni ea facilis earum qui.",
                id: "94ff8b14-815e-4f62-b285-181c463d147b",
            },
        ],
        id: "8f194089-8f3f-4dd5-9ddb-5999dc17db54",
    },
    {
        day: ["Середа", "СР"],
        training: [
            {
                time: "11:00",
                name: "BUTTY TRAINING",
                description:
                    "Lorem ipsum dolor sit, amet consectetur\nadipisicing elit. Voluptas at distinctio nobis\nmagni ea facilis earum qui.",
                id: "4a3644ee-5e6c-4061-813a-aba95a80d776",
            },
            {
                time: "18:00",
                name: "POWER",
                description:
                    "Lorem ipsum dolor sit, amet consectetur\nadipisicing elit. Voluptas at distinctio nobis\nmagni ea facilis earum qui.",
                id: "2878db6c-2870-4ae2-b006-b18f74dc7ba6",
            },
            {
                time: "19:00",
                name: "STRETCHING",
                description:
                    "Lorem ipsum dolor sit, amet consectetur\nadipisicing elit. Voluptas at distinctio nobis\nmagni ea facilis earum qui.",
                id: "663e5129-04b5-4cb2-9313-52a31e1df60b",
            },
        ],
        id: "1847a555-8dd5-43ad-ab24-7dfa75bd12f7",
    },
    {
        day: ["Четверг", "ЧТ"],
        training: [
            {
                time: "12:00",
                name: "FUNCTIONAL TRAINING",
                description:
                    "Lorem ipsum dolor sit, amet consectetur\nadipisicing elit. Voluptas at distinctio nobis\nmagni ea facilis earum qui.",
                id: "683bbaeb-39fa-41ed-b5e4-f3eddabc0fcb",
            },
            {
                time: "13:00",
                name: "STRETCHING",
                description:
                    "Lorem ipsum dolor sit, amet consectetur\nadipisicing elit. Voluptas at distinctio nobis\nmagni ea facilis earum qui.",
                id: "8a418a1a-c6dd-4022-b038-3d007abb7c5c",
            },
        ],
        id: "f715b9c6-8a44-4975-972c-87edf1bc37b9",
    },
    {
        day: ["П'ятниця", "ПТ"],
        training: [
            {
                time: "11:00",
                name: "TBW FITNESS",
                description:
                    "Lorem ipsum dolor sit, amet consectetur\nadipisicing elit. Voluptas at distinctio nobis\nmagni ea facilis earum qui.",
                id: "466b2269-96cd-4b2f-b5d7-82e16808e7e2",
            },
            {
                time: "18:00",
                name: "FITNESS MIX",
                description:
                    "Lorem ipsum dolor sit, amet consectetur\nadipisicing elit. Voluptas at distinctio nobis\nmagni ea facilis earum qui.",
                id: "a39ca95d-333f-4352-a8ea-c89427f24d62",
            },
            {
                time: "19:00",
                name: "STRETCHING",
                description:
                    "Lorem ipsum dolor sit, amet consectetur\nadipisicing elit. Voluptas at distinctio nobis\nmagni ea facilis earum qui.",
                id: "51ade206-23d8-4b4e-86d4-8e6ee35d395d",
            },
        ],
        id: "c6bcd60a-1eb2-4b87-8dd0-689a652b115d",
    },
    {
        day: ["Субота", "СБ"],
        training: [
            {
                time: "11:00",
                name: "HIIT",
                description:
                    "Lorem ipsum dolor sit, amet consectetur\nadipisicing elit. Voluptas at distinctio nobis\nmagni ea facilis earum qui.",
                id: "6ce15db8-eb9e-4228-b575-a830f7f442f0",
            },
            {
                time: "12:00",
                name: "STRETCHING",
                description:
                    "Lorem ipsum dolor sit, amet consectetur\nadipisicing elit. Voluptas at distinctio nobis\nmagni ea facilis earum qui.",
                id: "86e53222-c202-4286-a2c7-dfb3b754fa32",
            },
        ],
        id: "1fdde945-5f8e-490c-a902-bf8c147bfcc0",
    },
]
